import { PerformanceEvent } from '../../events';

import { PerformanceEventTiming } from './types';

const getTimingProperties = ({
  name,
  interactionId,
  processingStart,
  processingEnd,
  cancelable,
}: PerformanceEventTiming) => ({
  payload: JSON.stringify({
    name,
    interactionId,
    processingStart,
    processingEnd,
    cancelable,
  }),
});

type GetEventTimingPerformanceEvent = (entry: PerformanceEventTiming) => PerformanceEvent;
export const getEventTimingPerformanceEvent: GetEventTimingPerformanceEvent = (entry) => ({
  type: 'Performance',
  name: entry.entryType === 'first-input' ? 'first_input_delay' : 'event_timing',
  duration: entry.duration,
  startTime: entry.startTime,
  occurredAt: Date.now().toString(),
  properties: getTimingProperties(entry),
});
